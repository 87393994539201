/* eslint-disable react/prop-types */
import { Container, Dropdown, Navbar } from 'react-bootstrap';
import { RxAvatar } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PURGE } from 'redux-persist';
import { logoutUser } from '../../../redux/actions/userAction';
import CurrentTime from '../current-time/CurrentTime';

const AdminNavbar = ({ handleShowSidebar }) => {

  // const { user } = useSelector((state) => state.user);


  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()); // Dispatch the logoutUser action
      localStorage.removeItem("token");
      dispatch({ type: PURGE, key: "root", result: () => null });
      navigate("/login");
      window.location.reload();
      toast.success('Logged out successfully!'); // Show success message
    } catch (error) {
      console.error("Error logging out:", error.response?.data?.message || "An error occurred");
      toast.error(error.response?.data?.message || "An error occurred"); // Optionally show error message
    }
  };


  return (
    <Navbar expand="lg" className="navbar bg-dark top-nav" data-bs-theme="dark">
      <Container fluid className="d-flex justify-content-between align-items-center">
        {/* Left side (Brand and Toggle button) */}
        <div className="d-flex align-items-center">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleShowSidebar}
            className="custom-toggle me-2"
          />
          <Navbar.Brand
            as={Link}
            to="/"
            className="ms-2 ms-lg-0 bg-success px-1 px-sm-2 px-md-3 fs-7 fs-md-7 fs-lg-4">
           Starkward Enterprises
          </Navbar.Brand>


        </div>

        {/* Center (Current Time) */}
        <div className="d-none d-sm-flex flex-grow-1 justify-content-center align-items-center">
          <span className='text-white fs-5 fs-md-4 fs-lg-3'>
            <CurrentTime />
          </span>
        </div>



        {/* Right side (Avatar dropdown) */}
        <div className="navbar-link">
          <Dropdown>
            <Dropdown.Toggle as="div" className="avatar-toggle no-arrow">
              {/* {user && user ? (
                <img
                  src={user.image}
                  alt="User Avatar"
                  className="avatar-image"
                />
              ) : (
                <RxAvatar className="avatar-icon" />
              )} */}
              <RxAvatar className="avatar-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Link to="user-profile" className="dropdown-item">
                <i className="fas fa-user mr-2" /> Profile
              </Link>
              <div className="dropdown-divider" />
              <Link onClick={handleLogout} className="dropdown-item">
                <i className="fas fa-power-off mr-2" /> Log Out
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </Navbar>

  );
}

export default AdminNavbar;