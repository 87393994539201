// Add to cart
export const addTocart = (data) => (dispatch, getState) => {

  dispatch({
    type: 'addToCart',
    payload: data,
  });

  // Update local storage with the latest cart state
  const { cart } = getState().cart;
  localStorage.setItem('cartItems', JSON.stringify(cart));
};

// Remove from cart
export const removeFromCart = (data) => (dispatch, getState) => {
  dispatch({
    type: 'removeFromCart',
    payload: data.inventoryId, // Use inventoryId to identify the item
  });

  // Update local storage with the latest cart state
  const { cart } = getState().cart;
  localStorage.setItem('cartItems', JSON.stringify(cart));
};
