import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDeleteModal from "../../components/confirm-delete/ConfirmDeleteModal.jsx";
import CreateSubcategoryModal from "./CreateSubcategoryModal.jsx";
import EditSubcategoryModal from "./EditSubcategoryModal.jsx";
import { deleteSubcategory, getAllSubcategories } from "../../../redux/actions/subcategoryAction.js";

const SubCategoryPage = () => {
  // Fetch subcategory data from Redux
  const { subcategories, error, message, isLoading } = useSelector(
    (state) => state.subcategory
  );

  const dispatch = useDispatch();

  // State for modals
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingSubcategory, setEditingSubcategory] = useState(null);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [subcategoryIdToDelete, setSubcategoryIdToDelete] = useState(null);

  // Fetch subcategories when the component loads
  useEffect(() => {
    dispatch(getAllSubcategories());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, message]);

  // Delete subcategory handler
  const handleDelete = (subcategoryId) => {
    setSubcategoryIdToDelete(subcategoryId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSubcategory(subcategoryIdToDelete));
    setShowConfirmDeleteModal(false);
    setSubcategoryIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setSubcategoryIdToDelete(null);
  };

  // Edit subcategory handler
  const handleEdit = (subcategory) => {
    setEditingSubcategory(subcategory);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  return (
    <div className="container-fluid">
      <Row>
        <Col className="d-flex align-items-center">
          <h2 className="mb-0">Sub Categories</h2>
          <Button
            className="ms-auto"
            variant="primary"
            onClick={() => setShowCreateModal(true)}
          >
            Add Subcategory
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <div className="table-container">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th>Variants</th>{/* Add Variants column */}
                  <th>Pricing Attribute</th>{/* Add Pricing Attribute column */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {subcategories &&
                  subcategories.map((subcategory, index) => (
                    <tr key={subcategory._id}>
                      <td>{index + 1}</td>
                      <td>{subcategory?.name}</td>
                      <td>{subcategory?.category?.name}</td>
                      <td>{subcategory?.description}</td>
                      <td>
                        {/* Map over variants array to display each variant */}
                        {subcategory?.variants?.map((variant, variantIndex) => (
                          <span key={variantIndex} className="badge bg-primary me-1">
                            {variant}
                          </span>
                        ))}
                      </td>
                      <td>
                        {subcategory?.pricingAttribute}
                      </td>
                      <td>
                        <Button
                          variant="info"
                          onClick={() => handleEdit(subcategory)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          className="ms-2"
                          onClick={() => handleDelete(subcategory._id)}
                          disabled={isLoading}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          {showCreateModal && (
            <CreateSubcategoryModal
              show={showCreateModal}
              onHide={handleCreateModalClose}
            />
          )}
          {showEditModal && editingSubcategory && (
            <EditSubcategoryModal
              show={showEditModal}
              onHide={handleEditModalClose}
              subcategory={editingSubcategory}
            />
          )}
          <ConfirmDeleteModal
            show={showConfirmDeleteModal}
            onClose={handleCloseConfirmDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SubCategoryPage;
