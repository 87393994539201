/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { editProductUnit } from '../../../redux/actions/productUnitAction.js';

const EditProductUnitModal = ({ show, onHide, productUnit }) => {
  const dispatch = useDispatch();
  const [unitName, setUnitName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState(''); // Add state for quantity

  useEffect(() => {
    if (productUnit) {
      setUnitName(productUnit.unitName);
      setAbbreviation(productUnit.abbreviation);
      setDescription(productUnit.description);
      setQuantity(productUnit.quantity); // Set the initial value of quantity
    }
  }, [productUnit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedProductUnit = { unitName, abbreviation, description, quantity }; // Include quantity in submission
    dispatch(editProductUnit(productUnit._id, updatedProductUnit));
    onHide(); // Close modal after submission
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Product Unit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Unit Name</Form.Label>
            <Form.Control
              type="text"
              value={unitName}
              onChange={(e) => setUnitName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Abbreviation</Form.Label>
            <Form.Control
              type="text"
              value={abbreviation}
              onChange={(e) => setAbbreviation(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3"> {/* New quantity field */}
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductUnitModal;