/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllCategories } from "../../../redux/actions/categoryAction.js";
import { createProductVariant, getAllProductVariants } from "../../../redux/actions/productVariantAction.js";
import { getAllSubcategories } from "../../../redux/actions/subcategoryAction.js";

import { variantTypes } from "./data/variantTypes.jsx"; // Import the variant types
import { AiOutlineClose } from "react-icons/ai";

const CreateProductVariantModal = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const { isLoading, success, error } = useSelector((state) => state.productVariant);
  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.subcategory);

  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");

  const [variantType, setVariantType] = useState("Size");
  const [variationInput, setVariationInput] = useState("");
  const [variations, setVariations] = useState([]);

  const [availableSizes, setAvailableSizes] = useState([]);
  const [sizeInput, setSizeInput] = useState("");

  const [selectedType, setSelectedType] = useState("");


  useEffect(() => {
    if (show) {
      dispatch(getAllCategories());
      dispatch(getAllSubcategories());
    }
  }, [show, dispatch]);

  // Get subcategories based on selected category
  const subcategoryOptions = subcategories && subcategories.filter(
    (sub) => sub?.category?._id === category // Match with category ID
  );

  const handleAddVariation = () => {
    if (variationInput && !variations.includes(variationInput)) {
      setVariations([...variations, variationInput]);
      setVariationInput("");
    }
  };

  const handleRemoveVariation = (variationToRemove) => {
    setVariations(
      variations.filter((variation) => variation !== variationToRemove)
    );
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const productVariantData = {
      variantType,
      category,
      subcategory,
      availableSizes,
      variations,
      selectedType: selectedType,

    };
    // console.log(productVariantData.variations)
    dispatch(createProductVariant(productVariantData));
  };

  const handleAddSize = () => {
    if (sizeInput && !availableSizes.includes(sizeInput)) {
      setAvailableSizes([...availableSizes, sizeInput]);
      setSizeInput(""); // Clear the input field after adding
    }
  };

  const handleRemoveSize = (sizeToRemove) => {
    setAvailableSizes(availableSizes.filter(size => size !== sizeToRemove));
  };

  useEffect(() => {
    if (success) {
      toast.success("Product variant created successfully!");
      dispatch(getAllProductVariants());
      setVariantType("Size");
      setCategory("");
      setSelectedType(""); // Reset selected type
      setAvailableSizes([]);
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Create Product Variant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Variant Type</Form.Label>
            <Form.Control
              as="select"
              value={variantType}
              onChange={(e) => setVariantType(e.target.value)}
            >
              <option value="">Select variant type</option>
              {variantTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Variations</Form.Label>
            <InputGroup className="mb-2">
              <FormControl
                type="text"
                value={variationInput}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setVariationInput(
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
                  );
                }}
                placeholder={`Enter ${variantType.toLowerCase()} (e.g., S, M, L, XL)`}
              />
              <Button variant="outline-primary" onClick={handleAddVariation}>
                Add
              </Button>
            </InputGroup>
            <div>
              {variations.map((variation, index) => (
                <span
                  key={index}
                  className="badge bg-primary d-inline-flex align-items-center me-2"
                >
                  {variation}
                  <AiOutlineClose
                    size={15}
                    className="ms-2 cursor-pointer"
                    onClick={() => handleRemoveVariation(variation)}
                  />
                </span>
              ))}
            </div>
          </Form.Group>
          <Button className="float-end" variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Variant"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProductVariantModal;
