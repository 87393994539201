// Pricing Attributes for Inventory Management
export const pricingAttributes = [
    "Size (e.g., Small, Medium, Large)",
    "Weight (e.g., grams, kilograms, pounds)",
    "Dimensions (e.g., Length x Width x Height)",
    "Volume (e.g., liters, cubic meters)",
    "Capacity (e.g., liters, gallons)",
    "Material Type (e.g., plastic, metal, wood)",
    "Finish Type (e.g., glossy, matte, textured)",
    "Color",
    "Style/Design",
    "Brand",
    "Compliance Standards (e.g., ISO, ASTM)",
];

// Variant Types for Inventory Management
export const variantTypes = [
    "Size",
    "Color",
    "Weight",
    "Material",
    "Style",
    "Pattern",
    "Flavor",
    "Volume",
    "Length",
    "Width",
    "Height",
    "Quantity",
    "Finish",
    "Pack Size",
    "Shape",
    "Temperature",
    "Brand",
    "Dimensions",
    "Capacity",
    "Power",
    "Age Group",
    "Functionality",
    "Compatibility",
    "Texture",
    "Grade",
    "Season",
    "Usage",
    "Type",
    "Certification",
    "Collection",
];
