import axios from "axios";
import { server } from "../../server/server";

// Create Inventory Item
export const createInventoryItem = (newItem) => async (dispatch) => {
  try {
    dispatch({ type: "createInventoryItemRequest" });

    const { data } = await axios.post(`${server}/inventory/create-item`, newItem, { withCredentials: true });

    dispatch({
      type: "createInventoryItemSuccess",
      payload: data.item,
    });
    dispatch(getAllInventoryItems());
  } catch (error) {
    dispatch({
      type: "createInventoryItemFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Inventory Items
export const getAllInventoryItems = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllInventoryItemsRequest" });

    const { data } = await axios.get(`${server}/inventory`);

    dispatch({
      type: "getAllInventoryItemsSuccess",
      payload: data.inventoryItems,
    });
  } catch (error) {
    dispatch({
      type: "getAllInventoryItemsFailed",
      payload: error.response.data.message,
    });
  }
};

export const getInventoryByStore = (storeId) => async (dispatch) => {
  try {
    dispatch({ type: "getInventoryByStoreRequest" });
    const { data } = await axios.get(`${server}/inventory/store/${storeId}`, { withCredentials: true });
    //  console.log(data.inventoryItems)
    dispatch({
      type: "getInventoryByStoreSuccess",
      payload: data.inventoryItems,
    });

  } catch (error) {
    dispatch({
      type: "getInventoryByStoreFailed",
      payload: error.response.data.message,
    });
  }
};

// Transfer Inventory
export const transferInventory = (productId, transferData) => async (dispatch) => {
  try {
    dispatch({ type: "transferInventoryRequest" });

    const { data } = await axios.post(`${server}/inventory/transfer-inventory/${productId}`,
      transferData, { withCredentials: true });

    dispatch({
      type: "transferInventorySuccess",
      payload: data.message,
    });

    // Optionally, fetch products by store or all products to update the list
    // dispatch(getProductsByStore(storeId));
    // or
    // dispatch(getAllProducts());

  } catch (error) {
    dispatch({
      type: "transferInventoryFailed",
      payload: error.response.data.message,
    });
  }
};

// Add Inventory
export const addInventory = (inventoryId, quantity, currentStoreId) => async (dispatch) => {
  try {
    dispatch({ type: "addInventoryRequest" });

    // console.log(quantity)

    const { data } = await axios.put(`${server}/inventory/add-inventory/${inventoryId}`, { quantity }, { withCredentials: true });

    dispatch({
      type: "addInventorySuccess",
      payload: data.message,
    });

    // Optionally, fetch products by store or all products to update the list
    dispatch(getInventoryByStore(currentStoreId));
    // or
    // dispatch(getAllProducts());

  } catch (error) {
    dispatch({
      type: "addInventoryFailed",
      payload: error.response.data.message,
    });
  }
};


// Edit Inventory Item
export const editInventoryItem = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editInventoryItemRequest" });

    const { data } = await axios.put(`${server}/inventory/update-item/${id}`, updatedData);

    dispatch({
      type: "editInventoryItemSuccess",
      payload: data.item,
    });

    // Optionally, fetch all inventory items again to update the list
    dispatch(getAllInventoryItems());
  } catch (error) {
    dispatch({
      type: "editInventoryItemFailed",
      payload: error.response.data.message,
    });
  }
};

// reconcile Inventory Item
export const reconcileInventoryItem = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "InventoryItemRequest" });

    const { data } = await axios.put(`${server}/inventory/reconcile-item/${id}`, updatedData);

    dispatch({
      type: "reconcileInventoryItemSuccess",
      payload: data.item,
    });

    // Optionally, fetch all inventory items again to update the list
    dispatch(getAllInventoryItems());
  } catch (error) {
    dispatch({
      type: "reconcileInventoryItemFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Inventory Item
export const deleteInventoryItem = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteInventoryItemRequest" });

    const { data } = await axios.delete(`${server}/inventory/delete-item/${id}`);

    dispatch({
      type: "deleteInventoryItemSuccess",
      payload: data.message,
    });

    // Optionally, fetch all inventory items again to update the list
    dispatch(getAllInventoryItems());
  } catch (error) {
    dispatch({
      type: "deleteInventoryItemFailed",
      payload: error.response.data.message,
    });
  }
};
