// src/data/variantTypes.js

export const variantTypes = [
    "Size",
    "Color",
    "Weight",
    "Material",
    "Style",
    "Pattern",
    "Flavor",
    "Volume",
    "Length",
    "Width",
    "Height",
    "Quantity",
    "Finish",
    "Pack Size",
    "Shape",
    "Temperature",
    "Brand"
];
