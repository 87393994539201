import axios from "axios";
import { server } from "../../server/server";

// Create Product
export const createProduct = (newProduct) => async (dispatch) => {
  try {
    dispatch({ type: "createProductRequest" });

    // const config = { headers: { "Content-Type": "multipart/form-data" } }

    const { data } = await axios.post(
      `${server}/product/create-product`,
      newProduct,
      { withCredentials: true }
    );

    dispatch({
      type: "createProductSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "createProductFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllProductsRequest" });

    const { data } = await axios.get(`${server}/product/get-all-products`);

    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};

// Fetch Products by Store
export const getProductsByStore = (storeId) => async (dispatch) => {
  try {
    dispatch({ type: "getProductsByStoreRequest" });

    const { data } = await axios.get(
      `${server}/product/get-products-by-store/${storeId}`
    );

    dispatch({
      type: "getProductsByStoreSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getProductsByStoreFailed",
      payload: error.response.data.message,
    });
  }
};

// Fetch Product by ID
export const getProductById = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getProductByIdRequest" });

    const { data } = await axios.get(
      `${server}/product/get-product-by-id/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "getProductByIdSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "getProductByIdFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Product
export const editProduct = (updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editProductRequest" });

    // console.log(id);

    const { data } = await axios.put(
      `${server}/product/update-product/${updatedData.id}`,
      updatedData
    );

    dispatch({
      type: "editProductSuccess",
      payload: data.product,
    });

    // Optionally, fetch all products again to update the list
    dispatch(getAllProducts());
  } catch (error) {
    dispatch({
      type: "editProductFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteProductRequest" });

    const { data } = await axios.delete(
      `${server}/product/delete-product/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteProductSuccess",
      payload: data.message,
    });

    // Optionally, fetch all products again to update the list
    dispatch(getAllProducts());
  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};
