/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { editProductVariant } from "../../../redux/actions/productVariantAction.js";
import { AiOutlineClose } from "react-icons/ai";
import { getAllCategories } from "../../../redux/actions/categoryAction.js";
import { getAllSubcategories } from "../../../redux/actions/subcategoryAction.js";

const EditProductVariantModal = ({ show, onHide, productVariant }) => {
  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.subcategory);

  const [category, setCategory] = useState(""); // State for category
  const [subcategory, setSubcategory] = useState(""); // State for subcategory

  const [variantType, setVariantType] = useState("Size"); // Added state for variant type
  const [variationInput, setVariationInput] = useState("");
  const [variations, setVariations] = useState([]);

  const [selectedType, setSelectedType] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      dispatch(getAllCategories());
      dispatch(getAllSubcategories());
    }
  }, [dispatch, show])


  useEffect(() => {
    if (productVariant) {
      setCategory(productVariant?.category?._id || "");
      setSubcategory(productVariant?.subcategory?._id || "");
      setSelectedType(productVariant?.selectedType || "");
      setVariantType(productVariant?.variantType || "Size");
      setVariations(productVariant?.variations || []);
    }
  }, [productVariant]);

  // Get subcategories based on selected category
  const subcategoryOptions = subcategories && subcategories.filter(
    (sub) => sub?.category?._id === category // Match with category ID
  );


  const handleAddVariation = () => {
    if (variationInput && !variations.includes(variationInput)) {
      setVariations([...variations, variationInput]);
      setVariationInput("");
    }
  };

  const handleRemoveVariation = (variationToRemove) => {
    setVariations(
      variations.filter((variation) => variation !== variationToRemove)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const variantData = {
      category,
      subcategory,
      variantType,
      variations
    }
    // console.log(variantData)
    dispatch(
      editProductVariant(productVariant._id, variantData)
    );
    onHide(); // Close modal after submission
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Edit Product Variant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Control
              as="select"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
                setSubcategory(""); // Reset subcategory on category change
              }}
            >
              <option value="">Select category</option>
              {categories.map((cat) => (
                <option key={cat._id} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Subcategory</Form.Label>
            <Form.Control
              as="select"
              value={subcategory}
              onChange={(e) => setSubcategory(e.target.value)}
              disabled={!category} // Disable if no category is selected
            >
              <option value="">Select subcategory</option>
              {subcategoryOptions.map((sub) => (
                <option key={sub._id} value={sub._id}>
                  {sub.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Variant Type</Form.Label>
            <Form.Control
              as="select"
              value={variantType}
              onChange={(e) => setVariantType(e.target.value)}
              disabled
            >
              <option value="Size">Size</option>
              <option value="Color">Color</option>
              <option value="Weight">Weight</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Variations</Form.Label>
            <InputGroup className="mb-2">
              <FormControl
                type="text"
                value={variationInput}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setVariationInput(
                    inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
                  );
                }}
                placeholder={`Enter ${variantType.toLowerCase()} (e.g., S, M, L, XL)`}
              />
              <Button variant="outline-primary" onClick={handleAddVariation}>
                Add
              </Button>
            </InputGroup>
            <div>
              {variations.map((variation, index) => (
                <span
                  key={index}
                  className="badge bg-primary d-inline-flex align-items-center me-2"
                >
                  {variation}
                  <AiOutlineClose
                    size={15}
                    className="ms-2 cursor-pointer"
                    onClick={() => handleRemoveVariation(variation)}
                  />
                </span>
              ))}
            </div>
          </Form.Group>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductVariantModal;
