/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createProductUnit, getAllProductUnits } from '../../../redux/actions/productUnitAction';

const CreateProductUnitModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.productUnit);

  const [unitName, setUnitName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState(''); // Add state for quantity

  const handleSubmit = (e) => {
    e.preventDefault();

    const productUnitData = {
      unitName,
      abbreviation,
      description,
      quantity, // Include quantity in the form data
    };

    dispatch(createProductUnit(productUnitData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Product Unit created successfully!');
      dispatch(getAllProductUnits()); // Fetch updated product unit list
      setUnitName('');
      setAbbreviation('');
      setDescription('');
      setQuantity(''); // Reset quantity field
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Product Unit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Unit Name</Form.Label>
                <Form.Control
                  type="text"
                  value={unitName}
                  onChange={(e) => setUnitName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Abbreviation</Form.Label>
                <Form.Control
                  type="text"
                  value={abbreviation}
                  onChange={(e) => setAbbreviation(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6}> {/* New quantity field */}
              <Form.Group className="mb-3">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Product Unit'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProductUnitModal;
