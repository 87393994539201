/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getAllCategories } from "../../../redux/actions/categoryAction.js";
import { createSubcategory, getAllSubcategories } from "../../../redux/actions/subcategoryAction.js";
import { variantTypes, pricingAttributes } from "../../data/staticData.js"; // Assuming you have these arrays in staticData.js

const CreateSubcategoryModal = ({ show, onHide }) => {
  const dispatch = useDispatch();

  // Fetch categories to display in the dropdown
  const { categories } = useSelector((state) => state.category);

  // Subcategory creation state
  const { isLoading, success, error } = useSelector((state) => state.subcategory);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("Description");
  const [categoryId, setCategoryId] = useState("");
  const [availableVariants, setAvailableVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedPricingAttribute, setSelectedPricingAttribute] = useState("");

  const handleAddVariant = () => {
    const trimmedVariant = selectedVariant.trim();
    if (trimmedVariant && !availableVariants.includes(trimmedVariant)) {
      setAvailableVariants((prev) => [...prev, trimmedVariant]);
      setSelectedVariant("");
    } else if (availableVariants.includes(trimmedVariant)) {
      toast.error("This variant already exists!");
    }
  };

  const handleRemoveVariant = (index) => {
    setAvailableVariants((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Ensure a pricing attribute is selected
    if (!selectedPricingAttribute) {
      toast.error("Please select a pricing attribute.");
      return;
    }

    // Ensure at least one variant is available
    if (availableVariants.length === 0) {
      toast.error("Please add at least one variant.");
      return;
    }

    const subcategoryData = {
      name,
      description,
      category: categoryId,
      variants: availableVariants,
      pricingAttribute: selectedPricingAttribute, // Include the selected pricing attribute
    };

    // Dispatch the action to create the subcategory
    dispatch(createSubcategory(subcategoryData));
  };


  useEffect(() => {
    if (success) {
      toast.success("Subcategory created successfully!");
      dispatch(getAllSubcategories());
      setName("");
      setDescription("");
      setCategoryId("");
      setAvailableVariants([]);
      setSelectedVariant("");
      setSelectedPricingAttribute("");
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Sub Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Main Category</Form.Label>
            <Form.Select
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              required
            >
              <option value="">Select main category</option>
              {categories &&
                categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Sub Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter category name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Pricing Attribute</Form.Label>
            <Form.Select
              value={selectedPricingAttribute}
              onChange={(e) => setSelectedPricingAttribute(e.target.value)}
              aria-label="Select pricing attribute"
            // required
            >
              <option value="">Select pricing attribute</option>
              {pricingAttributes.map((attribute, index) => (
                <option key={index} value={attribute}>
                  {attribute}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Available Variants</Form.Label>
            <InputGroup>
              <Form.Select
                value={selectedVariant}
                onChange={(e) => setSelectedVariant(e.target.value)}
                aria-label="Select a variant"
              >
                <option value="">Select a variant</option>
                {variantTypes.map((variant, index) => (
                  <option key={index} value={variant}>
                    {variant}
                  </option>
                ))}
              </Form.Select>
              <Button variant="secondary" onClick={handleAddVariant}>
                Add Variant
              </Button>
            </InputGroup>
            <div className="mt-3">
              {availableVariants.map((variantItem, index) => (
                <span
                  key={index}
                  className="badge bg-primary d-inline-flex align-items-center me-2"
                >
                  {variantItem}
                  <AiOutlineClose
                    size={15}
                    className="ms-2 cursor-pointer"
                    onClick={() => handleRemoveVariant(index)}
                    aria-label={`Remove ${variantItem}`}
                  />
                </span>
              ))}
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>

          <Button className="float-end" variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Subcategory"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateSubcategoryModal;
