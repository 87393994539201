/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { editSubcategory } from '../../../redux/actions/subcategoryAction';
import { getAllCategories } from '../../../redux/actions/categoryAction';

const EditSubcategoryModal = ({ show, onHide, subcategory }) => {
    // Fetch categories to display in the dropdown
    const { categories } = useSelector((state) => state.category);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (subcategory) {
            setName(subcategory.name);
            setDescription(subcategory.description);
            setCategory(subcategory.category._id); // Set parent category ID
        }
    }, [subcategory]);

    useEffect(() => {
        dispatch(getAllCategories());
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(editSubcategory(subcategory._id, { name, description, category }));
        onHide(); // Close modal after submission
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Subcategory</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Subcategory Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Parent Category</Form.Label>
                        <Form.Select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            required
                        >
                            <option value="">Select a category</option>
                            {categories && categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" className='float-end' type="submit">
                        Update Category
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditSubcategoryModal;
