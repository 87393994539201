/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editProduct, getAllProducts } from "../../../../redux/actions/productAction.js";
import { getAllCategories } from "../../../../redux/actions/categoryAction.js";
import { getAllSubcategories } from "../../../../redux/actions/subcategoryAction.js";

import ColorSelector from "../utils/ColorSelector.jsx";
import SizeSelector from "../utils/size/SizeSelector.jsx";

const EditProductModal = ({ show, onHide, product }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.product);
  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.subcategory);

  const [name, setName] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");

  // const [selectedCategory, setSelectedCategory] = useState("");
  const [sizeDetails, setSizeDetails] = useState([]);
  const [size, setSize] = useState("");

  const [colors, setColors] = useState([]);
  const [color, setColor] = useState("");

  useEffect(() => {
    if (show) {
      dispatch(getAllCategories());
      dispatch(getAllSubcategories());
    }
  }, [show, dispatch]);

  const filterSubcategoriesByCategory = (category) => {
    if (!subcategories) return [];
    return subcategories.filter(
      (subcategory) => subcategory?.category?._id === category
    );
  };


  useEffect(() => {
    if (product) {
      setName(product.name);
      setCategory(product?.category?._id || "");
      setSubcategory(product?.subcategory?._id || "");
      setCostPrice(product.costPrice);
      setSellingPrice(product.sellingPrice);
      setColors(product.colors);
      setSizeDetails(product.sizeDetails);
      // setSelectedCategory(product.sizeDetails[0]?.sizeCategory);
    }
  }, [product]);


  const handleSubmit = (e) => {
    e.preventDefault();

    // if (sizeDetails.length === 0) {
    //   toast.error("Please add at least one size.");
    //   return;
    // }

    // Combine sizes and selectedCategory if needed
    const updatedSizeDetails = sizeDetails.map((item) => ({
      ...item,
      // sizeCategory: selectedCategory, // Add or update the selected category
    }));

    const productData = {
      id: product._id,
      name,
      category,
      subcategory,
      costPrice,
      sellingPrice,
      colors,
      sizeDetails: updatedSizeDetails, // Use the combined data
    };

    dispatch(editProduct(productData));
  };


  const handleAddColor = () => {
    if (color && !colors.includes(color)) {
      setColors((prevColors) => [...prevColors, color]);
      setColor("");
    }
  };

  const handleRemoveColor = (colorToRemove) => {
    setColors((prevColors) => prevColors.filter((c) => c !== colorToRemove));
  };

  const handleAddSize = (selectedCategory, size) => {
    if (size === "") {
      toast.error("Size cannot be empty");
      return;
    }

    setSizeDetails((prevDetails) => {

      const existingCategory = prevDetails.find(
        (item) => item.sizeCategory === size
      );

      if (existingCategory && existingCategory.sizes.includes(size)) {
        toast.error("This size has already been added");
        return prevDetails;
      }

      if (existingCategory) {
        // Update existing category's sizes
        return prevDetails.map((item) =>
          item.sizeCategory === selectedCategory
            ? { ...item, sizes: [...item.sizes, size] }
            : item
        );
      } else {
        // Add new category with sizes
        return [
          ...prevDetails,
          { sizeCategory: selectedCategory, sizes: [size] },
        ];
      }
    });

    // setSelectedCategory(selectedCategory);
  };

  const handleRemoveSize = (category, sizeToRemove) => {
    setSizeDetails((prevDetails) =>
      prevDetails
        .map((item) =>
          item.sizeCategory === category
            ? { ...item, sizes: item.sizes.filter((s) => s !== sizeToRemove) }
            : item
        )
        .filter((item) => item.sizes.length > 0)
    );
  };


  useEffect(() => {
    if (success) {
      dispatch(getAllProducts());
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      // dispatch(clearError()); // Uncomment if you have an action to reset the error state
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setSubcategory(""); // Reset subcategory when category changes
                  }}
                  required
                >
                  <option value="">Select a category</option>
                  {categories &&
                    categories.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Sub Category</Form.Label>
                <Form.Control
                  as="select"
                  value={subcategory}
                  onChange={(e) => setSubcategory(e.target.value)}
                  disabled={!category || !filterSubcategoriesByCategory(category).length}
                  required
                >
                  <option value="">Select a sub category</option>
                  {filterSubcategoriesByCategory(category).map((subcat) => (
                    <option key={subcat._id} value={subcat._id}>
                      {subcat.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>Cost Price</Form.Label>
                <Form.Control
                  type="number"
                  value={costPrice}
                  onChange={(e) => setCostPrice(e.target.value)}
                  min={0}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>Selling Price</Form.Label>
                <Form.Control
                  type="number"
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                  min={0}
                  required
                />
              </Form.Group>
            </Col>
            {/* Use ColorSelector component */}
            <ColorSelector
              colors={colors}
              color={color}
              onChange={(e) => setColor(e.target.value)} // Update color state
              onAddColor={handleAddColor}
              onRemoveColor={handleRemoveColor}
              subcategoryId={subcategory}
            />
            <SizeSelector
              sizeDetails={sizeDetails}
              onAddSize={handleAddSize}
              onRemoveSize={handleRemoveSize}
              selectedCategory={subcategory}
              // setSelectedCategory={setSelectedCategory}
              subcategoryId={subcategory}
              size={size}
              setSize={setSize}
            />
          </Row>
          <div className="text-end mt-4">
            <Button
              className="ms-3"
              type="submit"
              variant="primary"
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "Update Product"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductModal;
