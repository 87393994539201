/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Button, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { createCategory, getAllCategories } from "../../../redux/actions/categoryAction.js";
import { variantTypes } from "../../data/staticData.js";

const CreateCategoryModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.category);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("best of the best");
  const [availableIn, setAvailableIn] = useState("");
  const [availableType, setAvailableType] = useState("");
  const [availableTypes, setAvailableTypes] = useState([]);

  const handleAddAvailableType = () => {
    if (availableType) {
      setAvailableTypes([...availableTypes, availableType]);
      setAvailableType(""); // Clear the input field after adding
    }
  };

  const handleRemoveAvailableType = (indexToRemove) => {
    setAvailableTypes(availableTypes.filter((_, index) => index !== indexToRemove));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const categoryData = {
      name,
      description,
      availableIn,
      availableTypes,
    };

    dispatch(createCategory(categoryData));
  };

  useEffect(() => {
    if (success) {
      toast.success("Category created successfully!");
      dispatch(getAllCategories());
      setName("");
      setDescription("");
      setAvailableIn("");
      setAvailableTypes([]); // Reset available types
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Available In</Form.Label>
            <Form.Select
              value={availableIn}
              onChange={(e) => setAvailableIn(e.target.value)}
            >
              <option value="">Select Available Option</option>
              {variantTypes.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Available Types</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                value={availableType}
                onChange={(e) => setAvailableType(e.target.value)}
                placeholder="Add an available type"
              />
              <Button variant="outline-secondary" onClick={handleAddAvailableType}>
                Add
              </Button>
            </InputGroup>
            <ul>
              {availableTypes.map((type, index) => (
                <li key={index}>
                  {type}{" "}
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => handleRemoveAvailableType(index)}
                  >
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Category"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCategoryModal;
