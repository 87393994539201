/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { Button, Modal, Nav, Tab, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { addStock, transferStock } from "../../../redux/actions/stockAction";

const AdjustStockModal = ({ show, onClose, product }) => {
  const dispatch = useDispatch();
  const { stores } = useSelector((state) => state.store);
  const { error, success } = useSelector((state) => state.stock); // Extract error and success from the state

  const currentStoreId = product && product.store;

  const [selectedStore, setSelectedStore] = useState("");
  const [quantity, setQuantity] = useState("");
  const [addQuantity, setAddQuantity] = useState("");

  const handleStoreChange = (e) => setSelectedStore(e.target.value);
  const handleQuantityChange = (e) => setQuantity(e.target.value);
  const handleAddQuantityChange = (e) => setAddQuantity(e.target.value);

  const handleTransferSubmit = (e) => {
    e.preventDefault();
    const numericQuantity = Number(quantity); // Convert to number
    const transferData = { selectedStore, quantity: numericQuantity };

    if (product._id && selectedStore && numericQuantity) {
      dispatch(transferStock(product._id, transferData));
    }
  };


  const handleAddSubmit = (e) => {
    e.preventDefault();
    const numericAddQuantity = Number(addQuantity); // Convert to number

    if (product._id && numericAddQuantity) {
      dispatch(addStock(product._id, numericAddQuantity));
    }
  };


  useEffect(() => {
    if (success) {
      // toast.success("Operation successful");
      setSelectedStore("");
      setQuantity("");
      setAddQuantity("");
      onClose(); // Close the modal on success
    }
  }, [error, success, onClose]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error])


  const filteredStores = stores && stores.filter((store) => store._id !== currentStoreId);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Adjust Stock</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container id="left-tabs-example" defaultActiveKey="transfer">
          <Nav variant="pills" className="nav-pills nav-fill mb-2">
            <Nav.Item>
              <Nav.Link eventKey="transfer">Transfer Stock</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="add">Add Stock</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="transfer">
              <Form onSubmit={handleTransferSubmit}>
                <Form.Group controlId="formStore" className="mb-2">
                  <Form.Label>Receiving Store</Form.Label>
                  <Form.Control as="select" value={selectedStore} onChange={handleStoreChange}>
                    <option value="">Select a store</option>
                    {filteredStores && filteredStores.map((store) => (
                      <option key={store._id} value={store._id}>{store.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formQuantity">
                  <Form.Label>Quantity to Transfer</Form.Label>
                  <Form.Control
                    type="number"
                    value={quantity}
                    onChange={handleQuantityChange}
                    min="1"
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Transfer
                </Button>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="add">
              <Form onSubmit={handleAddSubmit}>
                <Form.Group controlId="formAddQuantity">
                  <Form.Label>Quantity to Add</Form.Label>
                  <Form.Control
                    type="number"
                    value={addQuantity}
                    onChange={handleAddQuantityChange}
                    min="1"
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Add Stock
                </Button>
              </Form>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default AdjustStockModal;