import axios from "axios";
import { server } from "../../server/server";
// Create Sale
export const createSale = (newSale) => async (dispatch) => {
  try {
    dispatch({ type: "createSaleRequest" });

    const { data } = await axios.post(`${server}/sale/create-sale`, newSale, { withCredentials: true });

    dispatch({
      type: "createSaleSuccess",
      payload: data.sale,
    });
  } catch (error) {
    // Determine the error message to display
    let errorMessage = "An unexpected error occurred.";

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      errorMessage = error.response.data.message || errorMessage;
    } else if (error.request) {
      // The request was made but no response was received
      errorMessage = "No response received from server.";
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage = error.message;
    }

    dispatch({
      type: "createSaleFail",
      payload: errorMessage,
    });
  }
};


// Fetch All Sales
export const getAllSales = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllSalesRequest" });

    const { data } = await axios.get(`${server}/sale/get-all-sales`, { withCredentials: true });

    dispatch({
      type: "getAllSalesSuccess",
      payload: data.sales,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch({
      type: "getAllSalesFailed",
      payload: errorMessage,
    });
  }
};

// Fetch Sales for a Specific Store (Based on Store ID from req.user)
export const getSalesByStore = () => async (dispatch) => {
  try {
    dispatch({ type: "getSalesByStoreRequest" });

    // Send GET request to fetch sales for the store associated with the logged-in user
    const { data } = await axios.get(`${server}/sale/store`, {
      withCredentials: true,  // Ensure the request is sent with the user's credentials
    });

    dispatch({
      type: "getSalesByStoreSuccess",
      payload: data.sales,
    });
  } catch (error) {
    dispatch({
      type: "getSalesByStoreFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};


// Fetch Single Sale by ID
export const getSaleById = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getSaleByIdRequest" });

    const { data } = await axios.get(`${server}/sales/sale/${id}`);

    dispatch({
      type: "getSaleByIdSuccess",
      payload: data.sale,
    });
  } catch (error) {
    dispatch({
      type: "getSaleByIdFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Sale
export const editSale = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editSaleRequest" });

    const { data } = await axios.put(`${server}/sales/sale/${id}`, updatedData);

    dispatch({
      type: "editSaleSuccess",
      payload: data.sale,
    });

    // Optionally, fetch all sales again to update the list
    dispatch(getAllSales());
  } catch (error) {
    dispatch({
      type: "editSaleFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Sale
export const deleteSale = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteSaleRequest" });

    const { data } = await axios.delete(`${server}/sales/sale/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteSaleSuccess",
      payload: data.message,
    });

    // Optionally, fetch all sales again to update the list
    dispatch(getAllSales());
  } catch (error) {
    dispatch({
      type: "deleteSaleFailed",
      payload: error.response.data.message,
    });
  }
};
