/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { colors, sizes } from "../../../data"; // Import sizes and colors from data.js
import { createInventoryItem } from "../../../redux/actions/inventoryAction"; // Import your action to add inventory
import { getAllProducts } from "../../../redux/actions/productAction";

const CreateInventory7 = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);

  // Independent states for product, size, color-quantity mapping, and total quantity
  const [product, setProduct] = useState("");
  const [size, setSize] = useState("");
  const [colorQuantities, setColorQuantities] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    dispatch(getAllProducts());
    // Initialize colorQuantities as an array of objects with colors and default quantity
    const initialQuantities = colors.map((color) => ({
      color,
      quantity: 0, // Default to 0
    }));
    setColorQuantities(initialQuantities);
  }, [dispatch]);

  const handleModalClose = () => {
    // Reset states
    setProduct("");
    setSize("");
    setColorQuantities({}); // Reset to an empty object
    setTotalQuantity(0); // Reset total quantity state
    handleClose(); // Call the original handleClose passed as a prop
  };

  // Handle input changes for quantity based on color and update total quantity
  const handleQuantityChange = (color, quantity) => {
    setColorQuantities((prevState) =>
      prevState.map((item) =>
        item.color === color
          ? { ...item, quantity: quantity === "" ? 0 : Number(quantity) }
          : item
      )
    );

    // Calculate new total quantity
    const updatedTotal = colorQuantities.reduce(
      (acc, item) => acc + (item.color === color ? Number(quantity) : item.quantity),
      0
    );
    setTotalQuantity(updatedTotal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields
    if (!product || !size || totalQuantity <= 0) {
      alert("Please select product, size, and enter valid quantities.");
      return;
    }

    const newItem = {
      product,
      size,
      quantities: colorQuantities,
      quantity: totalQuantity
    }

    // console.log(newItem)

    try {
      // Dispatch action to add the inventory item
      await dispatch(createInventoryItem(newItem));
      handleClose();
      // Reset states
      setProduct("");
      setSize("");
      setColorQuantities(colors.map((color) => ({ color, quantity: 0 }))); // Reset quantities
      setTotalQuantity(0); // Reset total quantity
    } catch (error) {
      console.error("Failed to add inventory item:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add Inventory Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formProduct">
            <Form.Label>Product</Form.Label>
            <Form.Control
              as="select"
              name="product"
              value={product}
              onChange={(e) => setProduct(e.target.value)} // Inline onChange handler
              required
            >
              <option value="">Select Product</option>
              {products.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formSize" className="mt-3">
            <Form.Label>Size</Form.Label>
            <Form.Control
              as="select"
              name="size"
              value={size}
              onChange={(e) => setSize(e.target.value)} // Inline onChange handler
              required
            >
              <option value="">Select Size</option>
              {sizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Display table only if a size is selected */}
          {size && (
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  {colors.map((color, index) => (
                    <th key={index}>
                      {color.charAt(0).toUpperCase() + color.slice(1)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {colorQuantities && colorQuantities.map((item) => (
                    <td key={item.color}>
                      <Form.Control
                        type="number"
                        min="0"
                        value={item.quantity || ""}
                        onChange={(e) =>
                          handleQuantityChange(item.color, e.target.value)
                        } // Handle changes for each color
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          )}

          {/* Total Quantity */}
          <Form.Group controlId="formTotalQuantity" className="mt-3">
            <Form.Label>Total Quantity</Form.Label>
            <Form.Control
              type="number"
              value={totalQuantity}
              readOnly
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3 float-end">
            Add Inventory
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateInventory7;