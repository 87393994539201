import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProductVariant,
  getAllProductVariants,
} from "../../../redux/actions/productVariantAction.js";
import ConfirmDeleteModal from "../../components/confirm-delete/ConfirmDeleteModal.jsx";

import CreateProductVariantModal from "./CreateProductVariantModal.jsx";
import EditProductVariantModal from "./EditProductVariantModal.jsx";

const VariantsPage = () => {
  const dispatch = useDispatch();
  const { productVariants, error, message, isLoading } = useSelector(
    (state) => state.productVariant
  );

  // console.log(productVariants)

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingProductVariant, setEditingProductVariant] = useState(null);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [productVariantIdToDelete, setProductVariantIdToDelete] =
    useState(null);

  useEffect(() => {
    dispatch(getAllProductVariants());
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      dispatch({ type: "clearSuccess" });
    }
  }, [error, message, dispatch]);

  const handleDelete = (productVariantId) => {
    setProductVariantIdToDelete(productVariantId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteProductVariant(productVariantIdToDelete));
    setShowConfirmDeleteModal(false);
    setProductVariantIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setProductVariantIdToDelete(null);
  };

  const handleEdit = (productVariant) => {
    setEditingProductVariant(productVariant);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setEditingProductVariant(null);
  };

  return (
    <div className="container-fluid">
      <Row className="mb-2">
        <Col className="d-flex align-items-center">
          <h2 className="mb-0">Product Variants</h2>
          <Button
            className="ms-auto"
            variant="primary"
            onClick={() => setShowCreateModal(true)}
          >
            Create Variation
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  {/* <th>Category</th> */}
                  {/* <th>Sub Category</th> */}
                  {/* <th>Type</th> */}
                  <th>Variations</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {productVariants &&
                  productVariants.map((variant, index) => (
                    <tr key={variant._id}>
                      <td>{index + 1}</td>
                      <td>{variant?.variantType}</td>
                      {/* <td>{variant?.category?.name}</td> */}
                      {/* <td>{variant?.subcategory?.name}</td> */}
                      {/* <td>{variant?.selectedType}</td> */}
                      <td>
                        {variant.variations
                          ? variant.variations.join(", ")
                          : "No variations"}
                      </td>
                      <td>
                        <Button
                          variant="info"
                          onClick={() => handleEdit(variant)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          className="ms-2"
                          onClick={() => handleDelete(variant._id)}
                          disabled={isLoading}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {showCreateModal && (
              <CreateProductVariantModal
                show={showCreateModal}
                onHide={handleCreateModalClose}
              />
            )}
            {showEditModal && editingProductVariant && (
              <EditProductVariantModal
                show={showEditModal}
                onHide={handleEditModalClose}
                productVariant={editingProductVariant}
              />
            )}
            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VariantsPage;
