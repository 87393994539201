import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash } from "react-icons/fa";

import ConfirmationAlert from "../../components/utils/ConfirmationAlert";
import { handleProductFilter } from "../../components/utils/handleFilter";

import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getAllProducts, } from "../../../redux/actions/productAction";

import CreateProductModal from "./create-product/CreateProductModal.jsx";
import EditProduct from "./edit-product/EditProductModal.jsx";

const ProductsPage = () => {
  const { products, error, message } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  const [records, setRecords] = useState(products || []);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showAlert, setShowAlert] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllProducts());

  }, [dispatch]);

  useEffect(() => {
    setRecords(products || []);
  }, [products]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleProductFilter(products, term); // Use the utility function
    setRecords(filteredData);
  };

  const handleEdit = (productId) => {
    const product = records.find((item) => item._id === productId);
    setSelectedProduct(product);
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    // setSelectedProduct(null);
  };

  const handleDelete = (productId) => {
    setProductToDelete(productId);
    setShowAlert(true);
  };

  const confirmDelete = () => {
    dispatch(deleteProduct(productToDelete)); // Dispatch the delete action
    setShowAlert(false);
    setProductToDelete(null);
  };

  const cancelDelete = () => {
    setShowAlert(false);
    setProductToDelete(null);
  };

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, message]);

  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Product Name",
      selector: (row) => row?.name,
    },
    {
      name: "Category",
      selector: (row) => row?.category?.name,
    },
    {
      name: "Sub Category",
      selector: (row) => row?.subcategory?.name,
    },
    {
      name: "Cost Price",
      selector: (row) => `Ksh.${row?.costPrice?.toFixed(2)}`,
    },
    {
      name: "Selling Price",
      selector: (row) => `Ksh.${row?.sellingPrice?.toFixed(2)}`,
    }
    ,
    {
      name: "Sizes",
      selector: (row) =>
        row?.sizeDetails
          ?.map((sizeDetail) => ` ${sizeDetail.sizes.join(", ")}`)
          .join("; ") || "N/A",
    },
    // {
    //   name: "Sizes Category",
    //   selector: (row) =>
    //     row?.sizeDetails
    //       ?.map((sizeDetail) => `${sizeDetail.sizeCategory}`)
    //       .join("; ") || "N/A",
    // },
    {
      name: "Colors",
      selector: (row) => ` ${row.colors.join(", ")}`,
    },
    // {
    //   name: "Store",
    //   selector: (row) => row?.store?.name,
    // },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          <span
            className="me-3 text-primary cursor-pointer"
            onClick={() => handleEdit(row._id)}
            title="Edit"
          >
            <FaEdit size={20} />
          </span>
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  return (
    <div className="container-fluid">
      <Row className="my-3 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Products</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={6} md={4}>
          <Button
            className="float-end"
            variant="primary"
            onClick={() => setShowCreateModal(true)}
          >
            Create Product
          </Button>
        </Col>
      </Row>
      <ConfirmationAlert
        show={showAlert}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              noDataComponent="No products found"
              striped
              highlightOnHover
              responsive
            />
          </div>
        </Col>
      </Row>
      {showCreateModal && (
        <CreateProductModal
          show={showCreateModal}
          onHide={handleCreateModalClose}
        />
      )}
      <EditProduct
        show={showEditModal}
        onHide={handleCloseModal}
        product={selectedProduct}
      />
    </div>
  );
};

export default ProductsPage;
