/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllCategories } from "../../../redux/actions/categoryAction.js";
import { createProductVariant, getAllProductVariants } from "../../../redux/actions/productVariantAction.js";
import { getAllSubcategories } from "../../../redux/actions/subcategoryAction.js";

import { variantTypes } from "./data/variantTypes.jsx"; // Import the variant types
import { AiOutlineClose } from "react-icons/ai";

const CreateProductVariantModal = ({ show, onHide }) => {
  const { isLoading, success, error } = useSelector((state) => state.productVariant);
  const { categories } = useSelector((state) => state.category); // Fetching categories from Redux store
  const dispatch = useDispatch();

  const [variantType, setVariantType] = useState("Size");
  const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
  const [availableSizes, setAvailableSizes] = useState([]); // State for available sizes
  const [sizeInput, setSizeInput] = useState(""); // State for the new size input

  useEffect(() => {
    if (show) {
      dispatch(getAllCategories());
      dispatch(getAllSubcategories());
    }
  }, [show, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const productVariantData = {
      variantType,
      category: selectedCategory, // Include selected category in the data
      availableSizes, // Include available sizes in the data
    };
    // console.log(productVariantData)
    dispatch(createProductVariant(productVariantData));
  };

  const handleAddSize = () => {
    if (sizeInput && !availableSizes.includes(sizeInput)) {
      setAvailableSizes([...availableSizes, sizeInput]);
      setSizeInput(""); // Clear the input field after adding
    }
  };

  const handleRemoveSize = (sizeToRemove) => {
    setAvailableSizes(availableSizes.filter(size => size !== sizeToRemove));
  };

  useEffect(() => {
    if (success) {
      toast.success("Product variant created successfully!");
      dispatch(getAllProductVariants());
      setVariantType("Size");
      setSelectedCategory("");
      setAvailableSizes([]);
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Create Product Variant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Variant Type</Form.Label>
            <Form.Control
              as="select"
              value={variantType}
              onChange={(e) => setVariantType(e.target.value)}
            >
              <option value="">Select variant type</option>
              {variantTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Control
              as="select"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select category</option>
              {categories.map((category, index) => (
                <option key={index} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Available Sizes</Form.Label>
            <div className="d-flex mb-2">
              <Form.Control
                type="text"
                placeholder="Add size (e.g., Small)"
                value={sizeInput}
                onChange={(e) => setSizeInput(e.target.value)}
              />
              <Button
                variant="secondary"
                onClick={handleAddSize}
                className="ms-2"
              >
                Add
              </Button>
            </div>
            {/* Display selected sizes as badges */}
            <div>
              {availableSizes.map((size, index) => (
                <span
                  key={index}
                  className="badge bg-primary d-inline-flex align-items-center me-2"
                >
                  {size}
                  <AiOutlineClose
                    size={15}
                    className="ms-2 cursor-pointer"
                    onClick={() => handleRemoveSize(size)}
                    aria-label={`Remove ${size}`}
                  />
                </span>
              ))}
            </div>
          </Form.Group>
          <Button className="float-end" variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Variant"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProductVariantModal;
