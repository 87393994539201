// data.js
export const sizes = ["small", "medium", "large", "extraLarge"]; // Add more sizes as needed
export const colors = ["red", "blue", "green",]; // Existing colors


export const availableSizes = ["37", "38", "39", "40"]; // Adjust as needed
export const availableColors = ["Black", "Brown"]; // Adjust as needed



{/* called at 15.40 */ }