import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../../redux/actions/cartAction";
import { getInventoryByStore } from "../../../redux/actions/inventoryAction";

const ProductSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const { cart } = useSelector((state) => state.cart);
  const { inventoryItems } = useSelector((state) => state.inventory);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const storeId = user?.store?._id;

  useEffect(() => {
    if (storeId) {
      dispatch(getInventoryByStore(storeId));
    }
  }, [dispatch, storeId]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim() === "") {
      setData([]);
    } else {
      const filterProducts = inventoryItems && inventoryItems
        .filter((stock) => stock?.product?.name?.toLowerCase().includes(term.toLowerCase()))
        .map((stock) => ({
          ...stock.product,
          quantity: stock.quantity,
          inventoryId: stock._id,
        }));
      setData(filterProducts);
    }
  };

  const addToCartHandler = (product) => {

    // Check if the inventory item is already in the cart
    const isItemExists = cart && cart.find((i) => i.inventoryId === product.inventoryId);

    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (!product || product.quantity < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...product, qty: 1 }; // Add quantity to cart data
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }

    setSearchTerm("");
    setData([]);
  };

  return (
    <div className="col-md-6">
      <h5>Search Product</h5>
      <div className="position-relative">
        <input
          type="text"
          placeholder="Search Product..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control"
          style={{ paddingRight: "2.5rem" }}
        />
        <AiOutlineSearch
          size={25}
          className="position-absolute"
          style={{ right: "0.5rem", top: "0.35rem", cursor: "pointer" }}
        />
        {searchTerm && data.length > 0 ? (
          <div
            className="position-absolute bg-light shadow-sm p-3"
            style={{ minHeight: "30vh", zIndex: 9, width: "100%" }}
          >
            {data && data.map((i, index) => (
              <div
                key={index}
                className="d-flex align-items-center mb-2"
                style={{ cursor: "pointer" }}
                onClick={() => addToCartHandler(i)}
              >
                <img
                  // src={i.image_Url[0].url}
                  alt=""
                  className="me-2"
                  style={{ width: "40px", height: "40px" }}
                />
                <div>
                  <h1 className="h6 mb-0">{i.name}</h1>
                </div>
                <div>
                  <h1 className="h6 ms-3 mb-0">{i.category?.name}</h1>
                </div>
                <div>
                  <h1 className="h6 ms-3 mb-0">{i.size}</h1>
                </div>
              </div>
            ))}
          </div>
        ) : searchTerm && data.length === 0 ? (
          <div
            className="position-absolute bg-light shadow-sm p-3"
            style={{ minHeight: "30vh", zIndex: 9, width: "100%" }}
          >
            <p className="text-center text-danger mb-0 fs-3">Product not found</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductSearch;
