/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { HiOutlineMinus, HiPlus } from 'react-icons/hi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

const CartSingle = ({ data, updateItem, quantityChangeHandler, removeFromCartHandler }) => {
    const [packaging, setPackaging] = useState('1pc');
    const [value, setValue] = useState(data.qty);

    const [price, setPrice] = useState(data.sellingPrice);


    // Handle price change
    const handlePriceChange = (e) => {
        const newPrice = e.target.value;

        // Check if newPrice is empty or valid
        if (newPrice === '' || parseFloat(newPrice) >= 0) {
            setPrice(newPrice);

            // Convert new price to float
            const parsedPrice = newPrice === '' ? 0 : parseFloat(newPrice);

            // Update in Cart only if newPrice is a valid number
            updateItem(data.inventoryId, parsedPrice, value);
        }
    };

    const handlePackagingChange = (e) => {
        const selectedPackaging = e.target.value;
        setPackaging(selectedPackaging);

        // Update value based on selected packaging
        const packagingValue = packagingValues[selectedPackaging] || 1;
        setValue(packagingValue); // Set value to the default quantity based on packaging

        // Update the item in the cart with the new packaging
        updateItem(data.inventoryId, price, packagingValue); // Using updateItem to reflect changes in the cart
    };

    const packagingValues = {
        '1pc': 1,
        'set': 6,
        'dozen': 12,
    };



    const increment = () => {
        const incrementValue = packagingValues[packaging] || 1;
        if (data.quantity < value + incrementValue) {
            toast.error(`Product stock limited! Available: ${data.quantity}`);
        } else {
            const newValue = value + incrementValue; // Calculate the new value
            setValue(newValue); // Update the local value state
            updateItem(data.inventoryId, price, newValue); // Update the item in the cart
            quantityChangeHandler({ ...data, qty: newValue }); // Update cart via quantityChangeHandler
        }
    };

    const decrement = () => {
        const decrementValue = packagingValues[packaging] || 1; // Get decrement value based on packaging
        const newValue = value - decrementValue; // Calculate new value

        // Check the minimum value based on the selected packaging
        if (packaging === 'set' && newValue < 6) {
            toast.info("Quantity cannot be less than 6 for a set.");
            return;
        }
        if (packaging === 'dozen' && newValue < 12) {
            toast.info("Quantity cannot be less than 12 for a dozen.");
            return;
        }
        if (newValue < 1) {
            toast.info("Quantity cannot be less than 1.");
            return;
        }

        setValue(newValue); // Update the local value state
        updateItem(data.inventoryId, price, newValue); // Update the item in the cart
        quantityChangeHandler({ ...data, qty: newValue }); // Update cart via quantityChangeHandler
    };

    // const totalPrice = (value * price).toFixed(2);

    const totalPrice = (value * (price > 0 ? price : data.sellingPrice)).toFixed(2);

    // Handle input blur
    const handleBlur = () => {
        if (price === '' || price === '0') {
            toast.info("Price has been reset to the initial selling price."); // Show toast notification
            setPrice(data.sellingPrice); // Reset to initial discounted price
            updateItem(data.inventoryId, data.sellingPrice, value); // Update in Cart with initial price
        } else if (parseFloat(price) < data.costPrice) {
            toast.error("New price cannot be lower than cost price."); // Show toast notification
            // Optionally, reset to the initial price if necessary
            setPrice(data.sellingPrice);
            updateItem(data.inventoryId, data.sellingPrice, value); // Update in Cart with initial price
        }
    };


    return (
        <tr>
            <td className="text-left  align-middle">{data.name}</td>
            <td className="text-center  align-middle">
                <div className="d-flex justify-content-center align-items-center">
                    <select
                        value={packaging}
                        onChange={handlePackagingChange}
                        className="form-select mx-1"
                    >
                        <option value="1pc">1 Piece</option>
                        <option value="set">Set (6 pcs)</option>
                        <option value="dozen">Dozen (12 pcs)</option>
                    </select>
                    <button
                        className="btn btn-sm btn-outline-secondary mx-1"
                        onClick={decrement}
                        disabled={value <= 1}
                        aria-label="Decrease quantity"
                    >
                        <HiOutlineMinus size={15} />
                    </button>
                    <span className="mx-2">{value}</span>
                    <button className="btn btn-sm btn-outline-secondary mx-1" onClick={increment}>
                        <HiPlus size={15} />
                    </button>
                </div>
            </td>
            <td className="text-right  align-middle">Ksh.{data.sellingPrice}</td>
            <td className='text-right  align-middle'>
                <input
                    type="number"
                    className="form-control me-2"
                    value={price}
                    onChange={handlePriceChange}
                    onBlur={handleBlur} // Add the blur event handler
                    min="0"
                    style={{ width: '100px' }}
                    aria-label="Price"
                />
            </td>
            <td className="text-right  align-middle">Ksh.{totalPrice}</td>
            <td className="text-center  align-middle">
                <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => removeFromCartHandler(data)}
                    aria-label="Remove item from cart"
                >
                    <RiDeleteBinLine size={20} />
                </button>
            </td>
        </tr>

    );
};

CartSingle.propTypes = {
    data: PropTypes.object.isRequired,
    quantityChangeHandler: PropTypes.func.isRequired,
    removeFromCartHandler: PropTypes.func.isRequired,
};

export default CartSingle;
